import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '1440px',
    boxSizing: 'border-box',
    margin: 'auto',
    backgroundColor: colors.gray.l96,
    paddingLeft: '48px',
    paddingRight: '48px',
    [theme.breakpoints.down(700)]: {
      paddingLeft: '0px',
      paddingRight: '0px',
    },
  },
  title: {
    textAlign: 'center',
    paddingTop: '96px',
    paddingBottom: '48px',
    fontSize: '44px',
    fontWeight: 700,
    lineHeight: '48px',
    letterSpacing: '-1px',
    [theme.breakpoints.down(900)]: {
      paddingTop: '48px',
      paddingBottom: '36px',
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '36px',
      letterSpacing: '-0.75px',
    },
  },
}));

interface StylesLargeDeviceProps {
  tabSelectorPosition: number;
  tabSelectorWidth: number;
}

export const useStylesLargeDevice = makeStyles((theme: Theme) => ({
  container: {
    marginTop: '24px',
  },
  tabs: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  tab: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      '& *': {
        color: colors.black,
      },
    },
  },
  currentTab: {
    '& *': {
      color: `${colors.black} !important`,
    },
  },
  tabImage: {
    height: '96px',
    width: '96px',
    marginBottom: '24px',
    [theme.breakpoints.down(900)]: {
      height: '68px',
      width: '68px',
      marginBottom: '18px',
    },
  },
  tabLabel: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
    letterSpacing: '0px',
    marginBottom: '12px',
    color: colors.concrete,
    [theme.breakpoints.down(900)]: {
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '14px',
    },
  },
  lineDivider: {
    height: '4px',
    width: '100%',
    background: colors.gray.l90,
    position: 'relative',
    left: '0',
    bottom: '0',
  },
  tabSelector: {
    height: '4px',
    width: ({ tabSelectorWidth }: StylesLargeDeviceProps) =>
      `${tabSelectorWidth}px`,
    background: colors.orange.l50,
    position: 'relative',
    left: ({ tabSelectorPosition }: StylesLargeDeviceProps) =>
      `${tabSelectorPosition}px`,
    bottom: '4px',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
  tabPanelContainer: {
    marginTop: '24px',
    display: 'grid',
    margin: 'auto',
  },
  cardsContainer: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(23%, auto))',
    rowGap: '24px',
    columnGap: '24px',
    [theme.breakpoints.down(1350)]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(31%, auto))',
    },
    [theme.breakpoints.down(1150)]: {
      gridTemplateColumns: 'repeat(auto-fill, minmax(48%, auto))',
    },
  },
  linksContainer: {
    gridTemplateColumns: 'repeat(auto-fill, minmax(19%, auto))',
    rowGap: '24px',
    columnGap: '12px',
    marginLeft: '96px',
    marginRight: '48px',
    [theme.breakpoints.down(1024)]: {
      marginLeft: '48px',
      marginRight: '24px',
    },
  },
  hiddenContainer: {
    display: 'none !important',
  },
  viewMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '48px',
    marginBottom: '96px',
    [theme.breakpoints.down(1024)]: {
      marginTop: '24px',
      marginBottom: '32px',
    },
  },
}));

export const useStylesSmallDevice = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  sectionContainer: {
    width: '100%',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    columnGap: '10px',
    alignItems: 'center',
    marginLeft: '20px',
    marginRight: '20px',
  },
  image: {
    width: '68px',
    height: '68px',
  },
  label: {
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '14px',
    letterSpacing: '0px',
  },
  lineDivider: {
    height: '2px',
    background: colors.gray.l90,
    position: 'relative',
    left: '0',
    bottom: '0',
    marginTop: '10px',
    marginBottom: '12px',
    marginLeft: '20px',
    marginRight: '20px',
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'flex-start',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Chrome, Safari and Opera
    '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
    scrollbarWidth: 'none', // Hide scrollbar for Firefox
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    flexWrap: 'nowrap',
    columnGap: '24px',
    paddingLeft: '24px',
    paddingRight: '24px',
  },
  linksContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
    rowGap: '24px',
    columnGap: '48px',
    paddingLeft: '24px',
    paddingRight: '24px',
    maxHeight: '440px',
  },
  viewMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '24px',
    marginBottom: '48px',
  },
});
