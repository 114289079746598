import React from 'react';
import { Box, Typography } from '@mui/material';
import Image from 'next/image';
import ImageLoader from '../utils/ImageLoader';
import { useStyles } from './CaseStudiesCard.styles';
import ArrowIcon from '../Icons/ArrowIcon';
import AnalyticsWrappedComponent from '../Analytics/AnalyticsWrappedComponent';

interface CaseStudiesCardProps {
  card: {
    image: string;
    title: string;
    description: string;
    href: string;
  };
  smView: boolean;
}

const CaseStudiesCard = ({
  card: { image, title, description, href },
  smView,
}: CaseStudiesCardProps) => {
  const classes = useStyles();

  return (
    <Box className={smView ? classes.smallScreenCard : classes.largeScreenCard}>
      <AnalyticsWrappedComponent
        componentType="a"
        eventText="Clicked Case Study Card"
        eventLabel={title}
        aria-label={title}
        className={smView ? classes.smallScreenLink : classes.largeScreenLink}
        href={href}
        rel="nofollow"
        data-track-click={`Case Studies, ${title}`}
      >
        <Box className={classes.container}>
          <Box className={classes.imageContainer}>
            <Image
              loader={ImageLoader}
              src={image}
              alt={`${title}`}
              width={271}
              height={157}
              className={classes.image}
              loading="eager"
              sizes="100vw"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
          <Box className={classes.bottomContainer}>
            <Box className={classes.textContainer}>
              <Typography variant="h4" className={classes.title}>
                {title}
              </Typography>
              <Typography variant="h5" className={classes.description}>
                {description}
              </Typography>
            </Box>
            <Box className={classes.arrowIcon}>
              <ArrowIcon />
            </Box>
          </Box>
        </Box>
      </AnalyticsWrappedComponent>
    </Box>
  );
};

export default CaseStudiesCard;
