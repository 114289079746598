import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const canonicalUrl = (path) => {
  const [currentPath, currentQuery] = path.replace(/^\/network/, '').split('?');
  const currentParams = currentQuery ? currentQuery.split('&') : [];

  const newParams = currentParams.filter(
    (p) => !p.startsWith('page=') && !p.startsWith('pageSize=')
  );

  const newQuery = newParams.length > 0 ? `?${newParams.join('&')}` : '';

  let canonical = publicRuntimeConfig.selfUrl + currentPath + newQuery;
  if (canonical.endsWith('/')) {
    canonical = canonical.slice(0, -1);
  }

  return canonical;
};
