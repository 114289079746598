import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginBottom: '30px',
    paddingLeft: '70px',
    paddingRight: '70px',
    [theme.breakpoints.down(800)]: {
      paddingLeft: '34px',
      paddingRight: '34px',
    },
    [theme.breakpoints.down(720)]: {
      marginBottom: '45px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
  },
  title: {
    textAlign: 'center',
    paddingTop: '96px',
    paddingBottom: '52px',
    fontSize: '44px',
    fontWeight: 700,
    lineHeight: '48px',
    letterSpacing: '-1px',
    [theme.breakpoints.down(800)]: {
      paddingTop: '48px',
      paddingBottom: '28px',
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '36px',
      letterSpacing: '-0.75px',
    },
  },
  cardsList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '12px',
    [theme.breakpoints.down(720)]: {
      rowGap: '46px',
    },
  },
}));
