import React from 'react';
import { Box, Typography, Theme, useMediaQuery } from '@mui/material';
import { useStyles } from './ValuePropCard.styles';
import LinkWithArrow from '../ButtonsLinks/LinkWithArrow';

interface ValuePropCardProps {
  eyebrow: string;
  title: string;
  content: string;
  buttonLabel: string;
  buttonLink: string;
  dataTrackClick: string;
  CardImage: JSX.Element;
  imageLeftAligned?: boolean;
}

const ValuePropCard = ({
  eyebrow,
  title,
  content,
  buttonLabel,
  buttonLink,
  dataTrackClick,
  CardImage,
  imageLeftAligned = false,
}: ValuePropCardProps) => {
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(720)
  );
  const createTextBeforeImage = !imageLeftAligned || isSmallScreen;

  return (
    <Box className={classes.cardContainer}>
      {!createTextBeforeImage && (
        <Box className={classes.image}>{CardImage}</Box>
      )}
      <Box className={classes.card}>
        <Typography className={classes.eyebrow}>{eyebrow}</Typography>
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography className={classes.content}>{content}</Typography>
        <LinkWithArrow
          buttonLabel={buttonLabel}
          buttonLink={buttonLink}
          dataTrackClick={dataTrackClick}
          analyticsLabel={title}
        />
      </Box>
      {createTextBeforeImage && (
        <Box className={classes.image}>{CardImage}</Box>
      )}
    </Box>
  );
};

export default ValuePropCard;
