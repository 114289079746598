import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: '1680px',
    boxSizing: 'border-box',
    margin: 'auto',
    paddingLeft: '48px',
    paddingRight: '48px',
    [theme.breakpoints.down(1280)]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
    [theme.breakpoints.down(600)]: {
      paddingLeft: '16px',
      paddingRight: '16px',
    },
  },
  graycard: {
    display: 'flex',
    backgroundColor: colors.gray.l96,
    [theme.breakpoints.down(1280)]: {
      flexWrap: 'wrap-reverse',
    },
  },
  videoOuterContainer: {
    width: 'calc(60% - 60px)',
    paddingLeft: '60px',
    [theme.breakpoints.down(1280)]: {
      height: '56.25%',
      width: '100%',
      paddingLeft: '0',
    },
  },
  videoInnerContainer: {
    position: 'relative',
    height: '125%',
    top: '-12.5%',
    [theme.breakpoints.down(1280)]: {
      width: '100%',
      height: '0',
      paddingBottom: '56.25%',
    },
  },
  videoIframe: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    left: '0',
    top: '0',
  },
  text: {
    width: '45%',
    padding: '60px 80px 60px 45px',
    [theme.breakpoints.down(1280)]: {
      width: '100%',
      padding: '60px 45px',
    },
    [theme.breakpoints.down(600)]: {
      padding: '30px 16px',
    },
  },
  label: {
    fontSize: '34px',
    fontWeight: 700,
    lineHeight: '40px',
    marginBottom: '24px',
    letterSpacing: '-1px',
    [theme.breakpoints.down(800)]: {
      marginBottom: '8px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '24px',
      lineHeight: '32px',
      letterSpacing: '0.15px',
    },
  },
  description: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    color: colors.concrete,
    marginBottom: '24px',
    [theme.breakpoints.down(800)]: {
      marginBottom: '8px',
    },
    [theme.breakpoints.down(600)]: {
      marginBottom: '24px',
    },
  },
}));
