import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(50%, 1fr))',
    [theme.breakpoints.down(720)]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      rowGap: '12px',
      gridTemplateColumns: 'unset',
    },
  },
  image: {
    [theme.breakpoints.down(720)]: {
      paddingLeft: '24px',
      paddingRight: '24px',
    },
  },
  card: {
    marginLeft: '92px',
    paddingRight: '32px',
    marginTop: 'auto',
    marginBottom: 'auto',
    [theme.breakpoints.down(1030)]: {
      marginLeft: '0px',
      paddingRight: '0px',
    },
  },
  eyebrow: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    letterSpacing: '1.25px',
    marginBottom: '12px',
  },
  title: {
    fontSize: '35px',
    fontWeight: 700,
    lineHeight: '30px',
    marginBottom: '24px',
    [theme.breakpoints.down(800)]: {
      fontSize: '26px',
      lineHeight: '34px',
      letterSpacing: '-0.75px',
    },
  },
  content: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    color: colors.concrete,
    marginBottom: '24px',
  },
}));
