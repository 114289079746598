import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    maxWidth: '1680px',
    paddingLeft: '48px',
    paddingRight: '48px',
    boxSizing: 'border-box',
    margin: 'auto',
    backgroundColor: colors.white,
    [theme.breakpoints.down(1350)]: {
      maxWidth: '100%',
      paddingLeft: '0',
      paddingRight: '0',
    },
  },
  imageContainer: {
    height: '358px',
    overflow: 'hidden',
    [theme.breakpoints.down(910)]: {
      height: '300px',
    },
  },
  textContainer: {
    height: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'calc(100% - 358px)',
    padding: '6%',
    color: colors.white,
    backgroundColor: colors.black,
    [theme.breakpoints.down(1000)]: {
      padding: '3%',
    },
    [theme.breakpoints.down(910)]: {
      padding: '6%',
      width: '70%',
    },
    [theme.breakpoints.down(570)]: {
      padding: '6% 24px',
      width: '100%',
    },
    [theme.breakpoints.down(400)]: {
      padding: '24px',
    },
  },
  title: {
    fontSize: '34px',
    fontWeight: 700,
    lineHeight: '40px',
    letterSpacing: '-1px',
    [theme.breakpoints.down(910)]: {
      fontSize: '26px',
      lineHeight: '34px',
      letterSpacing: '-0.75px',
    },
  },
  body: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0px',
    marginTop: '12px',
  },
  buttonContainer: {
    marginTop: '28px',
  },
  button: {
    height: '48px !important',
  },
}));
