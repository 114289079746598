import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '100%',
    height: '49vw',
    maxHeight: '820px',
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down(600)]: {
      height: '100%',
    },
  },
  imageContainer: {
    position: 'absolute',
    left: '0px',
    top: '0px',
    width: '100%',
    zIndex: -1,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '11%',
    maxWidth: '1000px',
    width: '60%',
    height: '100%',
    [theme.breakpoints.down(800)]: {
      width: '95%',
      marginLeft: '0px',
      paddingLeft: '20px',
      paddingRight: '20px',
    },
    [theme.breakpoints.down(600)]: {
      width: '100%',
    },
  },
  title: {
    fontSize: '65px',
    fontWeight: 700,
    lineHeight: '65px',
    letterSpacing: '-1.5px',
    whiteSpace: 'pre-wrap',
    color: colors.white,
    marginBottom: '72px',
    [theme.breakpoints.down(1070)]: {
      fontSize: '44px',
      fontWeight: 700,
      lineHeight: '48px',
    },
    [theme.breakpoints.down(680)]: {
      marginBottom: '35px',
    },
    [theme.breakpoints.down(600)]: {
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '36px',
      marginBottom: '56px',
      marginTop: '56px',
    },
  },
  subTitle: {
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.25px',
    color: colors.white,
    marginBottom: '12px',
    [theme.breakpoints.down(600)]: {
      fontSize: '20px',
      fontWeight: 700,
      lineHeight: '28px',
      letterSpacing: '0px',
    },
  },
  searchBarRow: {
    minWidth: '460px',
    maxWidth: '80%',
    [theme.breakpoints.down(600)]: {
      minWidth: '100%',
      maxWidth: '100%',
      marginBottom: '56px',
    },
  },
}));
