import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  container: {
    backgroundColor: colors.white,
  },
  textContainer: {
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  title: {
    textAlign: 'center',
    paddingTop: '38px',
    paddingBottom: '12px',
    fontSize: '44px',
    fontWeight: 700,
    lineHeight: '48px',
    letterSpacing: '-1px',
    [theme.breakpoints.down(900)]: {
      paddingTop: '15px',
      paddingBottom: '4px',
      fontSize: '28px',
      fontWeight: 700,
      lineHeight: '36px',
      letterSpacing: '-0.75px',
    },
    [theme.breakpoints.down(700)]: {
      paddingBottom: '0px',
    },
  },
  subTitle: {
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    letterSpacing: '0.15px',
  },
  scrollContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': { display: 'none' }, // Hide scrollbar for Chrome, Safari and Opera
    '-ms-overflow-style': 'none', // Hide scrollbar for IE and Edge
    scrollbarWidth: 'none', // Hide scrollbar for Firefox
    [theme.breakpoints.down(700)]: {
      justifyContent: 'flex-start',
    },
  },
  cardsContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    [theme.breakpoints.down(700)]: {
      justifyContent: 'space-between',
      paddingLeft: '24px',
      paddingRight: '24px',
      columnGap: '24px',
    },
  },
  readMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '48px',
    marginBottom: '30px',
    [theme.breakpoints.down(900)]: {
      marginTop: '30px',
      marginBottom: '45px',
    },
    [theme.breakpoints.down(700)]: {
      marginTop: '0',
      marginBottom: '36px',
    },
  },
}));
