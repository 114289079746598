import React from 'react';
import { Typography } from '@mui/material';
import { ArrowForward } from '@mui/icons-material';
import { useStyles } from './LinkWithArrow.styles';
import AnalyticsWrappedComponent from '../Analytics/AnalyticsWrappedComponent';

interface LinkWithArrowProps {
  buttonLabel: string;
  buttonLink: string;
  dataTrackClick: string;
  rel?: string;
  target?: string;
  id?: string;
  analyticsLabel: string;
  centered?: boolean;
  fadeToWhite?: boolean;
  component?: 'h2' | 'h3';
}

const LinkWithArrow = ({
  buttonLabel,
  buttonLink,
  dataTrackClick,
  rel,
  target,
  id,
  analyticsLabel,
  centered = false,
  fadeToWhite = false,
  component = 'h3',
}: LinkWithArrowProps) => {
  const classes = useStyles({ centered, fadeToWhite });
  return (
    <AnalyticsWrappedComponent
      componentType="a"
      eventText={`Clicked ${buttonLabel} Link`}
      eventLabel={analyticsLabel}
      aria-label={buttonLabel}
      className={classes.linkContainer}
      href={buttonLink}
      data-track-click={dataTrackClick}
      rel={rel}
      target={target}
      id={id}
    >
      <Typography component={component} className={classes.linkText}>
        {buttonLabel}
      </Typography>
      <ArrowForward />
    </AnalyticsWrappedComponent>
  );
};

export default LinkWithArrow;
