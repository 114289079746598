import React from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { useI18nContext } from '@procore/core-react';
import { useStyles } from './CaseStudies.styles';
import CaseStudiesCard from './CaseStudiesCard';
import LinkWithArrow from '../ButtonsLinks/LinkWithArrow';

export const cards = [
  {
    image: '/case-studies/peridot-mechanical.jpg',
    title: 'Peridot Mechanical',
    description:
      'Peridot Mechanical Triples Annual Revenue Using The Procore Construction Network',
    href: 'https://www.procore.com/casestudies/peridot-mechanical',
  },
  {
    image: '/case-studies/true-north-construction.webp',
    title: 'True North Construction',
    description: '"Are You Guys Going to be Using Procore...?"',
    href: 'https://www.procore.com/casestudies/true-north-construction',
  },
  {
    image: '/case-studies/oxford-properties.webp',
    title: 'Oxford Properties',
    description:
      'Oxford Properties Digitally Unifies its Portfolio to Propel Global Expansion',
    href: 'https://www.procore.com/casestudies/oxford-properties',
  },
];

const CaseStudies = () => {
  const I18n = useI18nContext();
  const classes = useStyles();
  const smView = useMediaQuery((theme: Theme) => theme.breakpoints.down(700));
  const mdView = useMediaQuery((theme: Theme) => theme.breakpoints.down(1000));

  const showLessCards = mdView && !smView;
  const maxCards = showLessCards ? 2 : 3;

  return (
    <Box className={classes.container}>
      <Box className={classes.textContainer}>
        <Typography variant="h2" className={classes.title}>
          {I18n.t('components.case_studies.companies_making_connections')}
        </Typography>
        <Typography variant="h3" className={classes.subTitle}>
          {I18n.t(
            'components.case_studies.see_how_companies_are_using_procore'
          )}
        </Typography>
      </Box>
      <Box className={classes.scrollContainer}>
        <Box className={classes.cardsContainer}>
          {cards.slice(0, maxCards).map((card) => (
            <CaseStudiesCard card={card} key={card.title} smView={smView} />
          ))}
        </Box>
      </Box>
      <Box className={classes.readMoreContainer}>
        <LinkWithArrow
          buttonLabel={I18n.t('components.case_studies.read_more_case_studies')}
          buttonLink="https://www.procore.com/casestudies"
          dataTrackClick="Explore, Read More Case Studies"
          centered
          analyticsLabel="Companies Making Connections"
        />
      </Box>
    </Box>
  );
};

export default CaseStudies;
