import React from 'react';
import colors from '../../styles/colors';

function ArrowIcon() {
  return (
    <svg
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="20.0022"
        cy="20.0022"
        r="19.0022"
        stroke={colors.gray.l60}
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.3309 18.4173L18.1217 10.955L20.3816 8.75L31.25 20L20.3816 31.25L18.1217 29.045L25.3309 21.5827L8.75 21.5827V18.4173L25.3309 18.4173Z"
        fill={colors.gray.l60}
      />
    </svg>
  );
}

export default ArrowIcon;
