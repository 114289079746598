import React from 'react';
import Image from 'next/image';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { useI18nContext } from '@procore/core-react';
import { useStyles } from './BuiltForConstruction.styles';
import JoinTheNetworkButton from '../ButtonsLinks/JoinTheNetworkButton';
import ImageLoader from '../utils/ImageLoader';

const BuiltForConstruction = () => {
  const I18n = useI18nContext();
  const classes = useStyles();
  const showImage = useMediaQuery((theme: Theme) => theme.breakpoints.up(570));
  const showSmallImage = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(910)
  );

  return (
    <Box className={classes.container}>
      {showImage && (
        <Box className={classes.imageContainer}>
          {showSmallImage ? (
            <Image
              loader={ImageLoader}
              src="/built-for-construction/small-banner.webp"
              alt="Built for construction banner"
              width={273}
              height={300}
              loading="eager"
            />
          ) : (
            <Image
              loader={ImageLoader}
              src="/built-for-construction/banner.webp"
              alt="Built for construction banner"
              width={546}
              height={358}
              loading="eager"
            />
          )}
        </Box>
      )}
      <Box className={classes.textContainer}>
        <Typography variant="h2" className={classes.title}>
          {I18n.t('components.built_for_construction.title')}
        </Typography>
        <Typography variant="h3" className={classes.body}>
          {I18n.t('components.built_for_construction.body')}
        </Typography>
        <Box className={classes.buttonContainer}>
          <JoinTheNetworkButton
            className={classes.button}
            text={I18n.t('components.built_for_construction.button_label')}
            dataTrackClick="Built For Construction"
            section="Built For Construction"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default BuiltForConstruction;
