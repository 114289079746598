import React from 'react';
import Image from 'next/image';
import { Box, Theme, Typography } from '@mui/material';
import { useI18nContext } from '@procore/core-react';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchBar from '../Search/SearchBar';
import { useStyles } from './Hero.styles';
import ImageLoader from '../utils/ImageLoader';

const Hero = () => {
  const I18n = useI18nContext();
  const classes = useStyles();
  const xmView = useMediaQuery((theme: Theme) => theme.breakpoints.down(414));
  const smView = useMediaQuery((theme: Theme) => theme.breakpoints.down(600));
  const mdView = useMediaQuery((theme: Theme) => theme.breakpoints.down(800));

  const title = I18n.t('components.hero.title');
  const subTitle = I18n.t('components.hero.sub-title');

  let HeroImage;
  if (smView) {
    HeroImage = (
      <Image
        loader={ImageLoader}
        src="/hero/small.webp"
        alt=""
        height={506}
        width={414}
        priority
        loading="eager"
        style={
          xmView
            ? {
                maxWidth: '100%',
                height: 'auto',
              }
            : {
                width: '100%',
                height: 'auto',
              }
        }
      />
    );
  } else if (mdView) {
    HeroImage = (
      <Image
        loader={ImageLoader}
        src="/hero/medium.webp"
        alt=""
        height={872}
        width={1536}
        priority
        loading="eager"
        sizes="100vw"
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
    );
  } else {
    HeroImage = (
      <Image
        loader={ImageLoader}
        src="/hero/large.webp"
        alt=""
        height={1412}
        width={2880}
        priority
        loading="eager"
        sizes="100vw"
        style={{
          width: '100%',
          height: 'auto',
        }}
      />
    );
  }

  return (
    <Box className={classes.container}>
      <Box className={classes.imageContainer}>{HeroImage}</Box>
      <Box className={classes.content}>
        <Typography variant="h1" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="h2" className={classes.subTitle}>
          {subTitle}
        </Typography>
        <Box className={classes.searchBarRow}>
          <SearchBar hero smView={smView} />
        </Box>
      </Box>
    </Box>
  );
};

export default Hero;
