import React from 'react';
import businessTypes from '../../data/filters/business-type';
import { DEFAULT_COUNTRY, CANADA } from '../../data/location/countries';
import { getStateList } from '../../data/location/states';
import marketSectors from '../../data/filters/market-sectors';
import { providedServices } from '../../data/filters/provided-services';
import routes from '../../helpers/routes';
import ExploreCard from './ExploreCard';
import ExploreLink from './ExploreLink';

export const exploreConstructionTradesCards = (nested = false) =>
  Object.values(providedServices)
    .filter((trade) => trade.icon)
    .map((trade) => (
      <ExploreCard
        key={trade.name}
        label={trade.name}
        href={routes.trades(trade)}
        image={trade.icon}
        nested={nested}
        section="Construction Trades"
      />
    ));

export const exploreCompanyTypesCards = (nested = false) =>
  Object.values(businessTypes).map((type) => (
    <ExploreCard
      key={type.name}
      label={type.name}
      href={routes.businessTypes(type.urlKey)}
      image={type.icon}
      nested={nested}
      section="Company Types"
    />
  ));

export const exploreMarketSectorsCards = (nested = false) =>
  Object.values(marketSectors).map((sector) => (
    <ExploreCard
      key={sector.name}
      label={sector.name}
      href={routes.marketSectors(sector.urlKey)}
      image={sector.icon}
      nested={nested}
      section="Market Sectors"
    />
  ));

export const exploreServiceAreasLinks = (country: string) => {
  const countryObject = country === 'CA' ? CANADA : DEFAULT_COUNTRY;
  return getStateList(countryObject).map((state) => (
    <ExploreLink
      key={state.abbreviation}
      label={state.name}
      href={routes.location(countryObject.alpha2, state.abbreviation)}
      section="Service Areas"
    />
  ));
};
