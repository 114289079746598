import React from 'react';
import classNames from 'classnames';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import Image from 'next/image';
import ImageLoader from '../utils/ImageLoader';
import { useSmallScreenStyles, useStyles } from './ExploreCard.styles';
import AnalyticsWrappedComponent from '../Analytics/AnalyticsWrappedComponent';

interface ExploreCardProps {
  label: string;
  href: string;
  image: string;
  nested?: boolean;
  section: string;
}

const ExploreCard = ({
  label,
  href,
  image,
  nested = false,
  section,
}: ExploreCardProps) => {
  let classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(700)
  );
  const smallScreenClasses = useSmallScreenStyles();
  if (isSmallScreen) {
    classes = smallScreenClasses;
  }

  return (
    <Box
      className={classes.card}
      id={`${section}-${label}`?.toLowerCase()?.replace(/ /g, '_')}
    >
      <AnalyticsWrappedComponent
        componentType="a"
        eventText={`Clicked Explore by ${section}`}
        eventLabel={label}
        aria-label={label}
        className={classNames(classes.linkContainer)}
        href={href}
        data-track-click={`Explore, ${label}`}
      >
        <Box className={classes.container}>
          <Box className={classes.imageContainer}>
            <Image
              loader={ImageLoader}
              src={image}
              alt=""
              width={279}
              height={157}
              className={classes.image}
              loading="eager"
              sizes="100vw"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
          <Typography
            component={nested ? 'h4' : 'h2'}
            variant="h4"
            className={classes.label}
            style={{ fontSize: '18px' }}
          >
            {label}
          </Typography>
        </Box>
      </AnalyticsWrappedComponent>
    </Box>
  );
};

export default ExploreCard;
