import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Box } from '@mui/material';
import classnames from 'classnames';
import { canonicalUrl } from '../helpers/canonical-url';
import { usePageView } from '../components/Analytics/utilities';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import Hero from '../components/Heros/Hero';
import ValueProp from '../components/ValueProp/ValueProp';
import BuiltForConstruction from '../components/BuiltForConstruction/BuiltForConstruction';
import CaseStudies from '../components/Discover/CaseStudies';
import Explore from '../components/Explore/Explore';
import ProcoreVideo from '../components/Marketing/ProcoreVideo';
import { useStyles } from './index.styles';

export async function getServerSideProps({ locale }) {
  const country = ['en-CA', 'fr-CA'].indexOf(locale) === -1 ? 'US' : 'CA';
  return {
    props: { country },
  };
}

export default function Home({ country }: { country: string }) {
  const router = useRouter();
  const classes = useStyles();
  const canonicalURL = canonicalUrl(router.asPath);
  usePageView('Home');

  return (
    <>
      <Head>
        <link rel="canonical" key="canonical" href={canonicalURL} />
      </Head>
      <Header hero />
      <section role="main">
        <Hero />
        <Box aria-label="Explore">
          <Explore country={country} />
        </Box>
        <Box
          className={classnames(
            classes.browseContainer,
            classes.procoreVideoContainer
          )}
        >
          <ProcoreVideo />
        </Box>
        <Box className={classes.browseContainer}>
          <ValueProp country={country} />
        </Box>
        <Box className={classes.browseContainer}>
          <CaseStudies />
        </Box>
        <Box className={classes.browseContainer}>
          <BuiltForConstruction />
        </Box>
      </section>
      <Footer />
    </>
  );
}
