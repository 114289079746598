import makeStyles from '@mui/styles/makeStyles';
import { Theme } from '@mui/material';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  card: {
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: '2px',
  },
  linkContainer: {
    textDecoration: 'none',
    marginTop: '24px',
    width: '328px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(1150)]: {
      width: '100%',
    },
    '&, & *': {
      transition: 'all 300ms ease',
    },
    '&:hover': {
      marginTop: '0px',
      '& $container': {
        backgroundColor: colors.white,
        boxShadow: '0px 2px 8px -1px rgba(35, 39, 41, 0.2)',
      },
      '& $imageContainer': {
        width: '328px',
        height: '181px',
      },
      '& $image': {
        borderRadius: '4px 4px 0px 0px',
      },
      '& $label': {
        marginLeft: '24px',
      },
    },
  },
  container: {
    borderRadius: '4px',
  },
  imageContainer: {
    width: '280px',
    height: '157px',
    marginBottom: '12px',
  },
  image: {
    borderRadius: '4px',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  label: {
    width: '280px',
    paddingBottom: '16px',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '32px',
    letterSpacing: '-0.25px',
    color: colors.black,
  },
}));

export const useSmallScreenStyles = makeStyles({
  card: {},
  linkContainer: {
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    padding: '30px 25px 32px 25px',
    borderRadius: '4px',
    backgroundColor: colors.white,
  },
  hiddenContainer: {
    overflow: 'hidden',
    height: '0px',
    margin: '0px',
    padding: '0px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    rowGap: '26px',
  },
  imageContainer: {
    width: '209px',
    height: '118px',
  },
  image: {
    borderRadius: '4px',
    width: '100%',
    height: '100%',
  },
  label: {
    width: '209px',
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '20px',
    letterSpacing: '0px',
    color: colors.black,
  },
});
