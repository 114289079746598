import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles({
  linkContainer: {
    textDecoration: 'none',
  },
  quebecLinkContainer: {
    cursor: 'default',
    pointerEvents: 'none',
  },
  label: {
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0px',
    color: colors.blue.l45,
    '&:hover': {
      textDecorationLine: 'underline',
    },
  },
  comingSoonPill: {
    marginTop: '5px',
    marginLeft: '-1px',
  },
});
