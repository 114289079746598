import React from 'react';
import { useI18nContext, Pill } from '@procore/core-react';
import { Typography } from '@mui/material';
import classnames from 'classnames';
import { useStyles } from './ExploreLink.styles';
import AnalyticsWrappedComponent from '../Analytics/AnalyticsWrappedComponent';

interface ExploreLinkProps {
  label: string;
  href: string;
  section: string;
}

const ExploreLink = ({ label, href, section }: ExploreLinkProps) => {
  const I18n = useI18nContext();
  const classes = useStyles();

  return (
    <AnalyticsWrappedComponent
      componentType="a"
      eventText={`Clicked Explore by ${section}`}
      eventLabel={label}
      aria-label={label}
      className={classnames(
        classes.linkContainer,
        label === 'Québec' ? classes.quebecLinkContainer : ''
      )}
      href={href}
      data-track-click={`Explore, ${label}`}
    >
      <Typography className={classes.label}>{label}</Typography>
      {label === 'Québec' && (
        <Pill color="blue" className={classes.comingSoonPill}>
          {I18n.t('components.discover_component.coming_soon')}
        </Pill>
      )}
    </AnalyticsWrappedComponent>
  );
};

export default ExploreLink;
