import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  browseContainer: {
    paddingBottom: '45px',
    backgroundColor: colors.white,
  },
  topContainer: {
    paddingTop: '45px',
  },
  procoreVideoContainer: {
    padding: '110px 0',
    [theme.breakpoints.down(1280)]: {
      padding: '80px 0 60px 0',
    },
    [theme.breakpoints.down(700)]: {
      padding: '60px 0 60px 0',
    },
    [theme.breakpoints.down(550)]: {
      padding: '50px 0 30px 0',
    },
  },
}));
