import { Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  smallScreenCard: {
    width: '271px',
    marginBottom: '2px',
  },
  largeScreenCard: {
    margin: '2px',
    width: '312px',
  },
  smallScreenLink: {
    textDecoration: 'none',
    '& *': {
      transition: 'all 300ms ease',
    },
    '&:hover': {
      '& svg > circle': {
        stroke: colors.orange.l50,
      },
      '& svg > path': {
        fill: colors.orange.l50,
      },
    },
  },
  largeScreenLink: {
    textDecoration: 'none',
    '& *': {
      transition: 'all 300ms ease',
    },
    '&:hover': {
      '& svg > circle': {
        stroke: colors.orange.l50,
      },
      '& svg > path': {
        fill: colors.orange.l50,
      },
      '& $container': {
        marginTop: '28px',
        width: '312px',
        backgroundColor: colors.white,
        boxShadow: '0px 2px 8px -1px rgba(35, 39, 41, 0.2)',
      },
      '& $imageContainer': {
        width: '312px',
        height: '181px',
      },
      '& $image': {
        borderRadius: '4px 4px 0px 0px',
      },
    },
  },
  container: {
    marginTop: '52px',
    margin: 'auto',
    height: '350px',
    width: '271px',
    borderRadius: '4px',
  },
  imageContainer: {
    width: '271px',
    height: '157px',
  },
  image: {
    borderRadius: '4px',
  },
  bottomContainer: {
    width: '271px',
    position: 'relative',
    margin: 'auto',
  },
  textContainer: {},
  title: {
    height: '74px',
    width: '80%',
    paddingTop: '16px',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '29px',
    color: colors.black,
    [theme.breakpoints.down(900)]: {
      fontSize: '20px',
      lineHeight: '24px',
    },
  },
  description: {
    marginTop: '25px',
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: colors.black,
  },
  arrowIcon: {
    position: 'absolute',
    top: '12px',
    right: '0px',
  },
}));
