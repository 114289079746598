import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useI18nContext } from '@procore/core-react';
import { useStyles } from './ProcoreVideo.styles';
import JoinTheNetworkButton from '../ButtonsLinks/JoinTheNetworkButton';

const ProcoreVideo = () => {
  const I18n = useI18nContext();
  const classes = useStyles();

  // There is a bug in NextJS where direct linking to an element ID does not consistently work.  This useEffect() acts as a workaround
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      /* istanbul ignore next */
      setTimeout(() => {
        document.querySelector(hash).scrollIntoView();
      }, 100);
    }
  });

  return (
    <Box id="video" className={classes.container}>
      <Box className={classes.graycard}>
        <Box className={classes.videoOuterContainer}>
          <Box className={classes.videoInnerContainer}>
            <iframe
              className={classes.videoIframe}
              allowFullScreen
              frameBorder="0"
              scrolling="no"
              src="https://fast.wistia.net/embed/iframe/qihb6j5lca"
              width="550"
              height="275"
              title="PCN Video"
              data-test-id="procore-video-iframe"
            />
          </Box>
        </Box>
        <Box className={classes.text} data-test-id="procore-video-text">
          <Typography variant="h2" className={classes.label}>
            {I18n.t('components.procore_video.label')}
          </Typography>
          <Typography className={classes.description}>
            {I18n.t('components.procore_video.description_part1')}{' '}
            <strong>
              {I18n.t('components.procore_video.description_bold1')}
            </strong>{' '}
            {I18n.t('components.procore_video.description_part2')}{' '}
            <strong>
              {I18n.t('components.procore_video.description_bold2')}
            </strong>{' '}
            {I18n.t('components.procore_video.description_part3')}
          </Typography>
          <JoinTheNetworkButton
            dataTrackClick="Procore Video"
            text="Sign Up"
            section="Procore Video"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ProcoreVideo;
