import React, { useEffect, useState } from 'react';
import { Box, Theme, Typography, useMediaQuery } from '@mui/material';
import Image from 'next/image';
import getConfig from 'next/config';
import { useI18nContext } from '@procore/core-react';
import classNames from 'classnames';
import { snakeCase } from 'lodash';
import ImageLoader from '../utils/ImageLoader';
import {
  useStyles,
  useStylesLargeDevice,
  useStylesSmallDevice,
} from './Explore.styles';
import {
  exploreCompanyTypesCards,
  exploreConstructionTradesCards,
  exploreMarketSectorsCards,
  exploreServiceAreasLinks,
} from './ExploreTabs';
import LinkWithArrow from '../ButtonsLinks/LinkWithArrow';
import routes from '../../helpers/routes';
import useWindowDimensions from '../../helpers/hooks/useWindowDimensions';

const { publicRuntimeConfig } = getConfig();

const getTabs = (country: string) => {
  const countryLink = `${publicRuntimeConfig.basePath}/${
    country === 'CA' ? 'ca' : 'us'
  }`;
  return [
    {
      id: 'construction-trades',
      cards: exploreConstructionTradesCards(true),
      buttonLink: routes.trades(),
    },
    {
      id: 'company-types',
      cards: exploreCompanyTypesCards(true),
      buttonLink: routes.businessTypes(),
    },
    {
      id: 'market-sectors',
      cards: exploreMarketSectorsCards(true),
      buttonLink: routes.marketSectors(),
    },
    {
      id: 'service-areas',
      cards: exploreServiceAreasLinks(country),
      buttonLink: countryLink,
    },
  ];
};

const ExploreLargeDevice = ({ country }: { country: string }) => {
  const tabs = getTabs(country);
  const [currentTab, setCurrentTab] = useState(tabs[0].id);
  const [tabSelectorPosition, setTabSelectorPosition] = useState(0);
  const [tabSelectorWidth, setTabSelectorWidth] = useState(0);
  const { windowWidth } = useWindowDimensions();
  const I18n = useI18nContext();
  const classes = useStylesLargeDevice({
    tabSelectorPosition,
    tabSelectorWidth,
  });
  const currentTabObject = tabs.find((tab) => tab.id === currentTab);
  const showLessCards = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(1350)
  );
  const maxCards = showLessCards ? 6 : 10;

  const handleOnClickTab = (id) => {
    setCurrentTab(id);
  };

  const handleKeyPressTab = (event, id) => {
    if ([13, 32].includes(event.keyCode || event.which)) {
      event.preventDefault();
      handleOnClickTab(id);
    }
  };

  useEffect(() => {
    const tab = document.getElementById(`explore-tab-${currentTab}`);
    setTabSelectorPosition(tab?.offsetLeft);
    setTabSelectorWidth(tab?.offsetWidth);
  }, [currentTab, windowWidth]);

  return (
    <Box className={classes.container}>
      <Box className={classes.tabs}>
        {tabs.map(({ id }) => (
          <Box
            className={classNames(
              classes.tab,
              id === currentTab && classes.currentTab
            )}
            key={id}
            onClick={() => handleOnClickTab(id)}
            id={`explore-tab-${id}`}
            data-test-id={`explore-tab-${id}`}
            tabIndex={0}
            onKeyPress={(event) => handleKeyPressTab(event, id)}
          >
            <Box className={classes.tabImage}>
              <Image
                loader={ImageLoader}
                src={`/explore-icons/${id}.png`}
                alt={I18n.t(`components.explore.${snakeCase(id)}`)}
                width={192}
                height={192}
                className={classes.tabImage}
                loading="eager"
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Box>
            <Typography variant="h3" className={classes.tabLabel}>
              {I18n.t(`components.explore.${snakeCase(id)}`)}
            </Typography>
          </Box>
        ))}
      </Box>
      <div className={classes.lineDivider} />
      <div className={classes.tabSelector} />
      {tabs.map(({ id, cards }) => (
        <Box
          key={id}
          data-test-id={`explore-cards-${id}`}
          className={classNames(
            classes.tabPanelContainer,
            id === 'service-areas'
              ? classes.linksContainer
              : classes.cardsContainer,
            id !== currentTab && classes.hiddenContainer
          )}
        >
          {id === 'service-areas' ? cards : cards.slice(0, maxCards)}
        </Box>
      ))}
      <Box className={classes.viewMoreContainer}>
        <LinkWithArrow
          buttonLabel={I18n.t(
            `components.explore.view_more_${snakeCase(currentTab)}`
          )}
          buttonLink={currentTabObject.buttonLink}
          dataTrackClick={`Explore, ${I18n.t(
            `components.explore.${snakeCase(currentTab)}`
          )}, View More`}
          centered
          analyticsLabel={currentTab}
        />
      </Box>
    </Box>
  );
};

const ExploreSmallDevice = ({ country }: { country: string }) => {
  const I18n = useI18nContext();
  const classes = useStylesSmallDevice();
  const tabs = getTabs(country);
  const maxCards = 10;

  return (
    <Box className={classes.container}>
      {tabs.map(({ id, cards, buttonLink }) => (
        <Box
          key={id}
          className={classes.sectionContainer}
          data-test-id={`explore-section-${id}`}
        >
          <Box className={classes.headerContainer}>
            <Box className={classes.image}>
              <Image
                loader={ImageLoader}
                src={`/explore-icons/${id}.png`}
                alt={I18n.t(`components.explore.${snakeCase(id)}`)}
                width={192}
                height={192}
                loading="eager"
                sizes="100vw"
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Box>
            <Typography variant="h3" className={classes.label}>
              {I18n.t(`components.explore.${snakeCase(id)}`)}
            </Typography>
          </Box>
          <div className={classes.lineDivider} />
          <Box className={classes.scrollContainer}>
            <Box
              className={
                id === 'service-areas'
                  ? classes.linksContainer
                  : classes.cardsContainer
              }
            >
              {id === 'service-areas' ? cards : cards.slice(0, maxCards)}
            </Box>
          </Box>
          <Box className={classes.viewMoreContainer}>
            <LinkWithArrow
              buttonLabel={I18n.t(
                `components.explore.view_more_${snakeCase(id)}`
              )}
              buttonLink={buttonLink}
              dataTrackClick={`Explore, ${I18n.t(
                `components.explore.${snakeCase(id)}`
              )}, View More`}
              centered
              analyticsLabel={id}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

const Explore = ({ country }: { country: string }) => {
  const I18n = useI18nContext();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(700)
  );

  return (
    <Box className={classes.container}>
      <Typography variant="h2" className={classes.title}>
        {I18n.t('components.explore.find_your_next_contractor')}
      </Typography>
      {isSmallScreen ? (
        <ExploreSmallDevice country={country} />
      ) : (
        <ExploreLargeDevice country={country} />
      )}
    </Box>
  );
};

export default Explore;
