import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import Image from 'next/image';
import getConfig from 'next/config';
import { useI18nContext } from '@procore/core-react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ImageLoader from '../utils/ImageLoader';
import { useStyles } from './ValueProp.styles';
import ValuePropCard from './ValuePropCard';
import { CTA_LINK } from '../../constants';

const { publicRuntimeConfig } = getConfig();

const ValueProp = ({ country }: { country: string }) => {
  const I18n = useI18nContext();
  const classes = useStyles();
  const { intakeForm } = useFlags();
  const countryLink = `${publicRuntimeConfig.basePath}/${
    country === 'CA' ? 'ca' : 'us'
  }`;

  return (
    <Container className={classes.container}>
      <Typography variant="h1" className={classes.title}>
        {I18n.t('components.value_prop.why_use_procore')}
      </Typography>
      <Box className={classes.cardsList}>
        <ValuePropCard
          eyebrow={I18n.t('components.value_prop.amplify')}
          title={I18n.t('components.value_prop.promote_your_business')}
          content={I18n.t('components.value_prop.show_what_sets_you_apart')}
          buttonLabel={I18n.t(
            'components.value_prop.create_your_free_business_page'
          )}
          buttonLink={
            intakeForm ? `${publicRuntimeConfig.basePath}/signup` : CTA_LINK
          }
          dataTrackClick="Value Prop, CTA, Create Your Free Business Page"
          CardImage={
            <Image
              loader={ImageLoader}
              src="/value-prop/promote-your-business.webp"
              alt="Promote your business"
              width={1140}
              height={1134}
              loading="eager"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          }
        />
        <ValuePropCard
          eyebrow={I18n.t('components.value_prop.connect')}
          title={I18n.t('components.value_prop.grow_your_network')}
          content={I18n.t('components.value_prop.discover_construction_pros')}
          buttonLabel={I18n.t('components.value_prop.discover_companies')}
          buttonLink={countryLink}
          dataTrackClick="Value Prop, CTA, Discover Companies"
          CardImage={
            <Image
              loader={ImageLoader}
              src="/value-prop/grow-your-network.webp"
              alt="Grow your network"
              width={1280}
              height={888}
              loading="eager"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          }
          imageLeftAligned
        />
        <ValuePropCard
          eyebrow={I18n.t('components.value_prop.explore')}
          title={I18n.t('components.value_prop.browse_for_free')}
          content={I18n.t('components.value_prop.quickly_surface')}
          buttonLabel={I18n.t('components.value_prop.start_searching')}
          buttonLink={countryLink}
          dataTrackClick="Value Prop, CTA, Start Searching"
          CardImage={
            <Image
              loader={ImageLoader}
              src="/value-prop/browse-for-free.webp"
              alt="Browse for free"
              width={970}
              height={965}
              loading="eager"
              style={{
                maxWidth: '100%',
                height: 'auto',
              }}
            />
          }
        />
      </Box>
    </Container>
  );
};

export default ValueProp;
