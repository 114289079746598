import makeStyles from '@mui/styles/makeStyles';
import colors from '../../styles/colors';

interface StylesProps {
  centered: boolean;
  fadeToWhite: boolean;
}

export const useStyles = makeStyles({
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: '12px',
    width: 'fit-content',
    textDecoration: 'none',
    color: colors.orange.l50,
    transition: 'all 300ms ease',
    '&:hover': {
      color: ({ fadeToWhite }: StylesProps) => {
        return fadeToWhite ? colors.white : colors.black;
      },
      columnGap: '18px',
      marginLeft: ({ centered }: StylesProps) => {
        return centered ? '6px' : '0px';
      },
    },
  },
  linkText: {
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '24px',
  },
});
